import { ChangeDetectorRef, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { from, Observable, ReplaySubject, Subject } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

type ObservableDictionary<T> = {
  [P in keyof T]: Observable<T[P]>;
};

type SubjectDictionary<T> = {
  [P in keyof T]: Subject<T[P]>;
};

type LoadingDictionary<T> = {
  [P in keyof T]: boolean;
};

type RestrictedKeys<T> = ObservableDictionary<T> & { loading?: never; $?: never };

export type StateObject<T = unknown> = Readonly<T> & {
  $: SubjectDictionary<T>;
  loading: LoadingDictionary<T>;
};

/**
 * Creates an object that is automatically updated whenever the inner Observables emit.
 * Also automatically unsubscribes all Observables when the component is destroyed.
 * Can be used to bind your observables to be used in components HTML template and auto-trigger change detection.
 *
 * @param component an Angular component
 * @param sources a mapping of keys to Observable values
 */
export function connectState<T>(sources: RestrictedKeys<T>) {
  const cdRef = inject(ChangeDetectorRef);
  const destroyRef = inject(DestroyRef);

  const sink = {
    $: {},
    loading: {},
  } as StateObject<T>;

  const sourceKeys = Object.keys(sources) as Array<keyof T>;
  for (const key of sourceKeys) {
    sink.$[key] = new ReplaySubject<T[keyof T]>(1);
    sink.loading[key] = true;
  }

  from(sourceKeys)
    .pipe(
      mergeMap((sourceKey: keyof T) => {
        const source$ = (sources as ObservableDictionary<T>)[sourceKey];

        // Useful for unit test debugging.
        if (!source$?.pipe) {
          throw new Error(`connectState: source of "state.${String(sourceKey)}" is not an Observable`);
        }

        return source$.pipe(
          tap((sinkValue: T[keyof T]) => {
            sink.loading[sourceKey] = false;
            sink.$[sourceKey].next(sinkValue);
            sink[sourceKey] = sinkValue as StateObject<T>[keyof T];
          }),
        );
      }),
    )
    .pipe(takeUntilDestroyed(destroyRef))
    // This function is used in so many places
    // that performance is more important than readability.
    // eslint-disable-next-line rxjs/no-subscribe-handlers
    .subscribe(() => cdRef.markForCheck());

  return sink;
}
