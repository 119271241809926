import { Platform as CdkPlatform } from '@angular/cdk/platform';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ConnectionStatus, Network } from '@capacitor/network';
import { FeatureFlagService } from '@examdojo/core/feature-flag';
import { ExamDojoFeatureFlag } from '@examdojo/models/feature-flag';
import { shareOneReplay } from '@examdojo/rxjs';
import { Platform as IonicPlatform } from '@ionic/angular/standalone';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
  merge,
  of,
  pairwise,
  startWith,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { LAYOUT_BREAKPOINTS, LayoutBreakpoints } from './layout-breakpoints';
import { Browser, MouseButton, OperatingSystem } from './platform.model';

@Injectable({
  providedIn: 'root',
})
export class PlatformService implements OnDestroy {
  constructor(
    private readonly cdkPlatform: CdkPlatform,
    private readonly ionicPlatform: IonicPlatform,
    private readonly featureFlagService: FeatureFlagService,
    @Inject(LAYOUT_BREAKPOINTS) private readonly layoutBreakpoints: LayoutBreakpoints,
  ) {
    this.listenForNetworkStatus().pipe(takeUntilDestroyed()).subscribe();
  }

  readonly isBrowser = this.cdkPlatform.isBrowser;
  readonly operatingSystem = this.guessOperatingSystem();
  readonly browser = this.guessBrowser();

  readonly isMobile = this.ionicPlatform.is('mobile');
  readonly isTablet = this.ionicPlatform.is('tablet');
  readonly isDesktop = this.ionicPlatform.is('desktop');

  readonly smallBreakpoint = this.layoutBreakpoints.small;
  readonly mediumBreakpoint = this.layoutBreakpoints.medium;
  readonly largeBreakpoint = this.layoutBreakpoints.large;

  readonly resize$ = this.ionicPlatform.resize.pipe(
    map(() => ({
      width: this.ionicPlatform.width(),
      height: this.ionicPlatform.height(),
    })),
  );

  readonly width$ = this.resize$.pipe(
    startWith({ width: this.width }),
    map(({ width }) => width),
    shareOneReplay(),
  );
  readonly height$ = this.resize$.pipe(
    startWith({ height: this.height }),
    map(({ height }) => height),
    shareOneReplay(),
  );

  readonly isSmall$ = this.resize$.pipe(
    startWith({ width: this.width }),
    map(({ width }) => width < this.smallBreakpoint),
    distinctUntilChanged(),
    shareOneReplay(),
  );

  readonly isLarge$ = this.resize$.pipe(
    startWith({ width: this.width }),
    map(({ width }) => width >= this.largeBreakpoint),
    distinctUntilChanged(),
    shareOneReplay(),
  );

  readonly activeStatusState$ = this.featureFlagService.select(ExamDojoFeatureFlag.TestingLock).pipe(
    switchMap((enabled) => {
      if (!enabled) {
        return of(true);
      }

      return combineLatest([
        this.networkStatus$,
        fromEvent(document, 'visibilitychange').pipe(
          map(() => document.visibilityState === 'visible'),
          startWith(document.visibilityState === 'visible'),
        ),
      ]).pipe(
        map(([networkStatus, isVisible]) => !!networkStatus?.connected && isVisible),
        distinctUntilChanged(),
      );
    }),
    shareOneReplay(),
  );

  readonly trigger$ = this.featureFlagService.select(ExamDojoFeatureFlag.TestingLock).pipe(
    filter(Boolean),
    switchMap(() => {
      return merge(
        this.networkStatus$.pipe(
          pairwise(),
          filter(([prev, curr]) => !!prev && !!curr && !prev.connected && curr.connected),
        ),
        this.pageVisible$.pipe(
          withLatestFrom(this.networkStatus$),
          filter(([, networkStatus]) => !!networkStatus?.connected),
          map(([event]) => event),
        ),
      ).pipe(debounceTime(100));
    }),
  );

  private readonly visibilitychange$ = fromEvent(document, 'visibilitychange');

  private readonly pageVisible$ = this.visibilitychange$.pipe(filter(() => document.visibilityState === 'visible'));

  private readonly networkStatus$ = new BehaviorSubject<ConnectionStatus | null>(null);

  get width() {
    return this.ionicPlatform.width();
  }

  get height() {
    return this.ionicPlatform.height();
  }

  ngOnDestroy() {
    Network.removeAllListeners();
  }

  isPrimaryMouseButtonEvent(e: MouseEvent): boolean {
    if (e.button !== MouseButton.Primary) {
      return false;
    }

    // On MacBook touch pads the right click can be simulated by pressing ctrl key while clicking the primary button (touchpad).
    if (this.operatingSystem === OperatingSystem.MacOS && e.ctrlKey) {
      return false;
    }

    return true;
  }

  isSecondaryMouseButtonEvent(e: MouseEvent): boolean {
    if (e.button === MouseButton.Secondary) {
      return true;
    }

    if (this.operatingSystem === OperatingSystem.MacOS && e.ctrlKey && e.button === MouseButton.Primary) {
      return true;
    }

    return false;
  }

  eventHasCmdOrCtrlModifier(event: KeyboardEvent | MouseEvent): boolean {
    return (
      (event.metaKey && this.operatingSystem === OperatingSystem.MacOS) ||
      (event.ctrlKey && this.operatingSystem !== OperatingSystem.MacOS)
    );
  }

  private guessOperatingSystem(): OperatingSystem {
    if (this.cdkPlatform.ANDROID) {
      return OperatingSystem.Android;
    }

    if (this.cdkPlatform.IOS) {
      return OperatingSystem.IOS;
    }

    if (/windows/i.test(navigator.userAgent)) {
      return OperatingSystem.Windows;
    }

    if (/Macintosh/i.test(navigator.userAgent)) {
      return OperatingSystem.MacOS;
    }

    if (/Linux/i.test(navigator.userAgent)) {
      return OperatingSystem.Linux;
    }

    return OperatingSystem.Other;
  }

  private guessBrowser(): Browser {
    if (this.cdkPlatform.EDGE) {
      return Browser.Edge;
    }

    if (this.cdkPlatform.FIREFOX) {
      return Browser.Firefox;
    }

    if (this.cdkPlatform.SAFARI) {
      return Browser.Safari;
    }

    if (this.cdkPlatform.WEBKIT) {
      return Browser.WebKit;
    }

    if (this.cdkPlatform.BLINK) {
      return Browser.Blink;
    }

    return Browser.Other;
  }

  private listenForNetworkStatus() {
    return this.featureFlagService.select(ExamDojoFeatureFlag.TestingLock).pipe(
      filter(Boolean),
      tap(async () => {
        this.networkStatus$.next(await Network.getStatus());

        Network.addListener('networkStatusChange', (status) => {
          this.networkStatus$.next(status);
        });
      }),
    );
  }
}
