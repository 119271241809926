import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ThemePalette } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BaseButton } from '../base-button';

@Component({
  selector: 'dojo-classic-button',
  templateUrl: './classic-button.component.html',
  styleUrls: ['./classic-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatProgressSpinnerModule, NgClass],
  providers: [{ provide: BaseButton, useExisting: ClassicButtonComponent }],
})
export class ClassicButtonComponent extends BaseButton {
  @Input() color?: ThemePalette | 'success';
  @Input() id?: string | null;
  @Input() class?: string | null;
  @Input() compact?: boolean | null;
  @Input() micro?: boolean | null;
  @Input() label?: string | null;
  @Input() isLabelStretched?: boolean;
  @Input() fullWidth?: boolean = false;
  @Input() isBorderShow?: boolean = false;

  @HostBinding('attr.disabled')
  get disabledAttr() {
    return this.disabled() ? true : undefined;
  }

  @HostBinding('style.pointer-events')
  get pointerEvents() {
    return this.disabled() || this.pending ? 'none' : undefined;
  }
}
