import { NgStyle } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  HostListener,
  input,
  viewChild,
} from '@angular/core';
import { BaseButton } from '@examdojo/core/button';
import { IonButton, IonSpinner } from '@ionic/angular/standalone';

export type ButtonExpand = IonButton['expand'];
export type ButtonFill = IonButton['fill'];

@Component({
  selector: 'dojo-button',
  imports: [IonButton, IonSpinner, NgStyle],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
  host: {
    '[attr.disabled]': 'this.disabled() ? true : undefined',
    '[style.pointer-events]': "this.disabled() || this.pending ? 'none' : undefined",
    '[style.--custom-color-name]': 'this.customColor() || undefined',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: BaseButton, useExisting: ButtonComponent }],
})
export class ButtonComponent extends BaseButton implements AfterViewChecked {
  readonly buttonRef = viewChild<unknown, ElementRef<IonButton>>('button', { read: ElementRef });

  readonly autoFocus = input<boolean>(false);
  readonly expand = input<ButtonExpand>();
  readonly small = input<boolean>(false);
  readonly micro = input<boolean>(false);
  readonly fill = input<ButtonFill>('solid');
  readonly style = input<Record<string, string>>({});

  readonly customColor = input<string>();
  readonly href = input<string>();
  readonly target = input<string>();
  readonly customWidth = input<number>();
  readonly customHeight = input<number>();

  // Set the tabIndex to `0` if the button is not disabled and autoFocus is set to true
  // Thanks to that the internal button will be focusable with the `this.buttonRef()?.nativeElement?.click(); method.
  // This is to support a rare case when we programmatically want to focus the ion-button that is inside a mat menu.
  // See https://github.com/ionic-team/ionic-framework/issues/21439
  readonly tabIndex = computed(() => {
    return !this.disabled() && this.autoFocus() ? 0 : -1;
  });

  ngAfterViewChecked(): void {
    if (this.autoFocus()) {
      setTimeout(() => {
        const ionButton = this.buttonRef()?.nativeElement || {};

        if ('focus' in ionButton && typeof ionButton.focus === 'function') {
          ionButton.focus();
        }
      }, 0);
    }
  }

  @HostListener('keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent): void {
    if ((event.key === ' ' || event.key === 'Enter') && !this.disabled()) {
      const ionButton = this.buttonRef()?.nativeElement || {};

      event.preventDefault(); // prevent moving the website.
      if ('click' in ionButton && typeof ionButton.click === 'function') {
        ionButton.click();
      }
    }
  }
}
