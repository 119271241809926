export const enum MouseButton {
  Primary = 0,
  Wheel = 1,
  Secondary = 2,
  BackButton = 3,
  ForwardButton = 4,
}

/**
 * The operation system.
 */
export enum OperatingSystem {
  Windows = 'Windows',
  MacOS = 'MacOS',
  Linux = 'Linux',
  Android = 'Android',
  IOS = 'IOS',
  Other = 'Other',
}

/**
 * The browser rendering engine.
 *
 * Chrome is either `WebKit` (iOS) or `Blink` for other OS.
 */
export enum Browser {
  WebKit = 'WebKit',
  Firefox = 'Firefox',
  Edge = 'Edge',
  Safari = 'Safari',
  Blink = 'Blink',
  Other = 'Other',
}
