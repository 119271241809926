import { InjectionToken, Provider } from '@angular/core';

export enum LayoutBreakpoint {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export type LayoutBreakpoints = Record<LayoutBreakpoint, number>;

export const LAYOUT_BREAKPOINTS = new InjectionToken<LayoutBreakpoints>('layout_breakpoints');

export function provideLayoutBreakpoints(breakpoints: LayoutBreakpoints): Provider {
  return { provide: LAYOUT_BREAKPOINTS, useValue: breakpoints };
}
