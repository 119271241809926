import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Type } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ThemePalette } from '@angular/material/core';
import { DomSanitizer } from '@angular/platform-browser';
import { getTrackByFn } from '@examdojo/angular/util';
import { Icon, IconComponent } from '@examdojo/core/icon';
import { loadingIcon, provideFaIcons } from '@examdojo/icons';
import {
  faCheck,
  faCircleExclamation,
  faCircleInfo,
  faClose,
  faTriangleExclamation,
} from '@fortawesome/pro-light-svg-icons';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { HotToastRef, Toast } from '@ngxpert/hot-toast';
import { MarkdownModule } from 'ngx-markdown';
import { Subject } from 'rxjs';

export interface RichToastMessage {
  title: string;
  description?: string;
  markdown?: string;
}
export type ToastMessage = string | RichToastMessage | Type<unknown> | undefined;

export interface ToastAction {
  id: string;
  label: string;
  color?: ThemePalette;
}

export interface ToastData<Action extends ToastAction> {
  message?: ToastMessage;
  actionClicked: Subject<Action['id']>;
  actions?: Action[];
  [key: string]: unknown;
}

export interface ToastClose {
  id: string;
  dismissedByAction: boolean;
}

const ToastTypeToIcon: Record<Toast<unknown>['type'], Icon> = {
  success: 'check',
  error: 'circle-exclamation',
  warning: 'triangle-exclamation',
  info: 'circle-info',
  loading: loadingIcon,
  blank: 'circle-info',
};

@Component({
  selector: 'y42-toast',
  imports: [IconComponent, MatButtonModule, MarkdownModule, NgIf],
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent<Action extends ToastAction = ToastAction> {
  constructor(
    public readonly toastRef: HotToastRef,
    private readonly sanitizer: DomSanitizer,
  ) {
    provideFaIcons([faCheck, faCircleExclamation, faTriangleExclamation, faCircleInfo, faClose]);
  }

  readonly toast = this.toastRef.getToast();
  readonly type = this.toast.type;
  readonly data: ToastData<Action> = (this.toast.data as ToastData<Action>) ?? {
    actions: [],
    actionClicked: new Subject(),
  };
  private readonly message = this.data['message'] as ToastMessage | undefined;
  readonly toastTitle = typeof this.message === 'object' ? this.message.title : this.message;
  readonly toastDescription = typeof this.message === 'object' ? this.message.description : undefined;
  readonly toastMarkdown = typeof this.message === 'object' ? this.message.markdown : undefined;
  readonly actions = this.data['actions'];
  readonly icon = ToastTypeToIcon[this.type];
  readonly trackByID = getTrackByFn<ToastAction>('id');
  readonly dismissible = this.toast.dismissible ?? true;
  readonly sanitizedTitle =
    typeof this.toastTitle === 'string' ? this.sanitizer.bypassSecurityTrustHtml(this.toastTitle) : null;
  readonly sanitizedDescription =
    typeof this.toastDescription === 'string' ? this.sanitizer.bypassSecurityTrustHtml(this.toastDescription) : null;

  close(id?: Action['id']) {
    if (id) {
      this.data.actionClicked?.next(id);
    }
    this.toastRef.close({
      dismissedByAction: true,
    });
  }
}
