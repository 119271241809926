import { Directive, HostListener, Input } from '@angular/core';
import { ToastService } from '@examdojo/toast';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[cdkCopyToClipboard]',
  standalone: false,
})
export class CopyToClipboardToastDirective {
  constructor(private readonly toast: ToastService) {}
  @Input() cdkCopyToClipboard?: string;

  @HostListener('click')
  onClick() {
    if (this.cdkCopyToClipboard) {
      this.toast.success('Copied to Clipboard');
    }
  }
}
