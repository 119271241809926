import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RxInput, RxOnInit } from '@examdojo/angular/util';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { combineLatest, merge, Observable, tap } from 'rxjs';
import { FaIcon, Icon, ImgIcon, isFaIcon, isImgIcon, isSvgIcon, SvgIcon } from '../icon.model';

export type TStyles = Record<string, unknown>;
export type IconSizeType = number | string | { height: number | string; width: number | string };

export const DEFAULT_ICON_SIZE = 14;

export type IconType = 'auto' | 'svg' | 'img' | 'fa';

@RxOnInit()
@UntilDestroy()
@Component({
  selector: 'dojo-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FontAwesomeModule, AngularSvgIconModule, NgStyle],
})
export class IconComponent {
  constructor() {
    merge(this.maintainIconConfigs()).pipe(untilDestroyed(this)).subscribe();
  }

  @Input()
  @RxInput()
  icon: Icon | undefined;
  private readonly icon$!: Observable<Icon | undefined>;

  @Input()
  @RxInput()
  type: IconType | undefined;
  private readonly type$!: Observable<IconType | undefined>;

  @Input()
  @RxInput()
  size: IconSizeType = DEFAULT_ICON_SIZE;
  private readonly size$!: Observable<IconSizeType>;

  @Input() viewBox?: string;
  @Input() faPrefix: IconPrefix = 'far';
  @Input() alt?: string;
  @Input() renderSvgAsImg = false;
  @Input() iconClass?: string;
  @Input() keepFaIconRatio = false;

  protected svgIcon?: SvgIcon;
  protected imgIcon?: ImgIcon;
  protected faIcon?: FaIcon;
  protected extendedIconStyle: TStyles = {};

  private setIcon(icon: Icon | undefined, type: IconType | undefined): void {
    this.svgIcon = undefined;
    this.imgIcon = undefined;
    this.faIcon = undefined;

    if (!icon) {
      return;
    }

    switch (type) {
      case 'svg':
        this.svgIcon = icon as SvgIcon;
        return;
      case 'img':
        this.imgIcon = icon as ImgIcon;
        return;
      case 'fa':
        this.faIcon = icon as FaIcon;
        return;

      case 'auto':
      case undefined:
        break;
    }

    if (isSvgIcon(icon) && !this.renderSvgAsImg) {
      this.svgIcon = icon;
    } else if (isImgIcon(icon) || (isSvgIcon(icon) && this.renderSvgAsImg)) {
      this.imgIcon = icon as ImgIcon;
    } else if (isFaIcon(icon)) {
      this.faIcon = icon;
    }
  }

  private setIconStyle(size: IconSizeType) {
    const extendedIconStyle: TStyles = {};
    const formattedSize = (value: number | string) => {
      return typeof value === 'number' ? `${value}px` : value;
    };

    if (typeof size === 'object') {
      extendedIconStyle['height'] = formattedSize(size.height);
      extendedIconStyle['width'] = formattedSize(size.width);
    } else {
      const formattedSizeValue = formattedSize(size);

      if (this.faIcon) {
        extendedIconStyle['fontSize.px'] = size;
      } else {
        extendedIconStyle['height'] = formattedSizeValue;
        extendedIconStyle['width'] = formattedSizeValue;
      }
    }

    this.extendedIconStyle = extendedIconStyle;
  }

  private maintainIconConfigs() {
    return combineLatest([this.icon$, this.size$, this.type$]).pipe(
      tap(([icon, size, type]) => {
        this.setIcon(icon, type);
        this.setIconStyle(size);
      }),
    );
  }
}
