import { areArraysEqual, AreArraysEqualParams } from '@examdojo/util/are-arrays-equal';
import isEqual from 'lodash/isEqual';
import { distinctUntilChanged } from 'rxjs';

export function distinctUntilArrayChanged<T extends unknown[]>(params?: AreArraysEqualParams) {
  return distinctUntilChanged<T>((a, b) => areArraysEqual(a, b, params));
}

export function distinctUntilDeepChanged<T>() {
  return distinctUntilChanged<T>(isEqual);
}
