import { Provider } from '@angular/core';
import { FEATURE_FLAGS, FeatureFlags } from './feature-flag.injection-token';
import { FeatureFlagService } from './feature-flag.service';

export function provideFeatureFlag(featureFlags: FeatureFlags): Provider[] {
  return [
    {
      provide: FEATURE_FLAGS,
      useValue: featureFlags,
    },
    FeatureFlagService,
  ];
}
