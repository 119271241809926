import { AfterViewInit, Directive, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject, delay, filter, map, Observable, Subject } from 'rxjs';

/**
 * @deprecated use the Rx lifecycle hook decorators instead
 */
@Directive({
  selector: '[y42ReactiveLifecycleHooks]',
  standalone: false,
})
export class ReactiveLifecycleHooksDirective implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  private readonly ngOnInit$$ = new BehaviorSubject<boolean>(false);
  private readonly ngOnChanges$$ = new Subject<SimpleChanges>();
  private readonly ngAfterViewInit$$ = new BehaviorSubject<boolean>(false);
  private readonly ngOnDestroy$$ = new BehaviorSubject<boolean>(false);

  readonly ngOnInit$: Observable<void> = this.ngOnInit$$.pipe(
    filter(Boolean),
    map(() => undefined),
  );
  readonly ngOnChanges$: Observable<SimpleChanges> = this.ngOnChanges$$;
  readonly ngAfterViewInit$: Observable<void> = this.ngAfterViewInit$$.pipe(
    filter(Boolean),
    map(() => undefined),
    // without delay any ui state updates done in the subscription won't be picked up immediately by the Angular change detection mechanism
    // and will appear only after the next change detection cycle, which leads to UI and state being out sync for some time
    delay(1),
  );
  readonly ngOnDestroy$: Observable<void> = this.ngOnDestroy$$.pipe(
    filter(Boolean),
    map(() => undefined),
  );

  ngOnInit(): void {
    this.ngOnInit$$.next(true);
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnChanges$$.next(changes);
  }
  ngAfterViewInit(): void {
    this.ngAfterViewInit$$.next(true);
  }
  ngOnDestroy(): void {
    this.ngOnDestroy$$.next(true);
  }
}
