import { Environment } from './environment.model';
import { getEnvVar } from './get-env-var';

/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * IMPORTANT:
 *
 * ALL ENV_VARS NEED TO BE APPLIED TO `env.{envName}.js`
 * OTHERWISE THEY WILL NOT BE INCLUDED.
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */
export const environment: Environment = {
  production_build: true,
  name: getEnvVar('APP_NAME'),
  release_version: getEnvVar('APP_VERSION'),
  supabase: {
    apiUrl: getEnvVar('SUPABASE_API_URL'),
    publicKey: getEnvVar('SUPABASE_PUBLIC_KEY'),
  },
  examdojo: {
    examdojoApiUrl: getEnvVar('EXAMDOJO_API_URL'),
    questionEngagementApi: getEnvVar('QUESTION_ENGAGEMENT_API_URL'),
    cmsApiUrl: getEnvVar('EXAMDOJO_CMS_API_URL'),
    studentsApiUrl: getEnvVar('STUDENTS_API_URL'),
    billingApiUrl: getEnvVar('BILLING_API_URL'),
  },
  posthog: {
    key: getEnvVar('POSTHOG_KEY'),
    apiHost: getEnvVar('POSTHOG_API_HOST'),
    uiHost: getEnvVar('POSTHOG_UI_HOST'),
  },
  stripe: {
    key: getEnvVar('STRIPE_KEY'),
  },
};
