import { Injector } from '@angular/core';
import { ENV_NAME, EnvName } from '@examdojo/core/environment';
import { FeatureFlags } from '@examdojo/core/feature-flag';
import { ExamDojoFeatureFlag } from './feature-flag.enum';

const _enableInLocal = (injector: Injector) => injector.get(ENV_NAME) === EnvName.Local;
const _enableInLocalAndDev = (injector: Injector) => [EnvName.Local, EnvName.Dev].includes(injector.get(ENV_NAME));
const _enableInProd = (injector: Injector) => [EnvName.Production].includes(injector.get(ENV_NAME));

// Define default values for feature flags.
//
// These values will be overriden by PostHog at runtime
// if the flag is defined in the PostHog dashboard,
// and the PostHog targeting logic matches the current context (user).
//
export const EXAMDOJO_FEATURE_FLAGS = {
  [ExamDojoFeatureFlag.Testing]: false,
  [ExamDojoFeatureFlag.HideSolutionDialogQrCode]: false,
  [ExamDojoFeatureFlag.TestingLock]: false,
  [ExamDojoFeatureFlag.Billing]: false,
} satisfies FeatureFlags<ExamDojoFeatureFlag>;

export type ExamdojoFeatureFlags<K extends keyof typeof EXAMDOJO_FEATURE_FLAGS = keyof typeof EXAMDOJO_FEATURE_FLAGS> =
  Pick<typeof EXAMDOJO_FEATURE_FLAGS, K>;
