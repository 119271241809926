import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { connectState, RxInput, RxOnInit } from '@examdojo/angular/util';
import { Icon, IconModule } from '@examdojo/core/icon';
import { provideFaIcons } from '@examdojo/icons';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { MtxTooltipModule } from '@ng-matero/extensions/tooltip';
import { combineLatest, map, Observable } from 'rxjs';

@RxOnInit()
@Component({
  selector: 'y42-info-icon',
  imports: [NgClass, IconModule, MtxTooltipModule, NgTemplateOutlet],
  host: { class: 'inline-flex cursor-pointer' },
  templateUrl: './info-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoIconComponent {
  constructor() {
    provideFaIcons([faInfoCircle]);
  }

  @Input({ required: true })
  @RxInput()
  message!: string | TemplateRef<unknown>;
  private readonly message$!: Observable<string | TemplateRef<unknown>>;

  @Input() icon?: Icon | null;

  @Input()
  @RxInput()
  infoIconDisabled = false;
  private readonly infoIconDisabled$!: Observable<boolean>;

  @Input()
  @RxInput()
  iconColorClass = 'text-gray-900';
  private readonly iconColorClass$!: Observable<string>;

  @Input() size = 12;

  readonly state = connectState({
    tooltipTmplMessage: this.message$.pipe(map((message) => (message instanceof TemplateRef ? message : null))),
    iconColor: combineLatest([this.iconColorClass$, this.infoIconDisabled$]).pipe(
      map(([color, disabled]) => (disabled ? 'text-typography-disabled' : color)),
    ),
  });
}
