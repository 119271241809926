// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { provideHotToastConfig } from '@ngxpert/hot-toast';
import { ToastConfig } from '@ngxpert/hot-toast/lib/hot-toast.model';
import { DEFAULT_HOT_TOAST_STYLE } from './toast.model';

const DEFAULT_CONFIG: Partial<ToastConfig> = {
  position: 'top-right',
  style: DEFAULT_HOT_TOAST_STYLE,
  icon: '',
  duration: 4000,
  className: 'hot-toast-success',
  loading: {
    icon: '',
    className: 'hot-toast-info',
  },
  warning: {
    icon: '',
    className: 'hot-toast-warning',
  },
  error: {
    icon: '',
    className: 'hot-toast-error',
  },
  info: {
    icon: '',
    className: 'hot-toast-info',
  },
  blank: {
    icon: '',
    className: 'hot-toast-info',
  },
};

export function provideToastConfig(config?: Partial<ToastConfig>) {
  return provideHotToastConfig(config ?? DEFAULT_CONFIG);
}
