import { debounceTime, Observable, startWith } from 'rxjs';

export interface ResizeObservableOptions {
  debounceTime: number;
  emitOnStart: boolean;
  omitDebounce: boolean;
}

const defaultOptions: ResizeObservableOptions = {
  debounceTime: 100,
  emitOnStart: false,
  omitDebounce: false,
};

export function fromResize(elem: HTMLElement, options?: Partial<ResizeObservableOptions>): Observable<DOMRectReadOnly> {
  const settings = Object.assign({}, defaultOptions, options);

  let resize$ = new Observable<DOMRectReadOnly>((subscriber) => {
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.target === elem) {
          subscriber.next(entry.contentRect);
        }
      });
    });

    resizeObserver.observe(elem);

    return () => {
      resizeObserver.disconnect();
    };
  });

  if (settings.emitOnStart) {
    resize$ = resize$.pipe(startWith(elem.getBoundingClientRect()));
  }

  if (settings.omitDebounce) {
    return resize$;
  }

  return resize$.pipe(debounceTime(settings.debounceTime));
}
