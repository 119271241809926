<ion-button
  #button
  class="ion-button"
  [class.small]="small()"
  [class.micro]="micro()"
  [expand]="expand()"
  [disabled]="disabled() || pending"
  [type]="type()"
  [ngStyle]="{
    width: customWidth() ? customWidth() + 'px' : '100%',
    height: customHeight() ? customHeight() + 'px' : 'auto',
    minHeight: customHeight() ? customHeight() + 'px' : 'auto'
  }"
  [style]="style()"
  [fill]="fill()"
  [tabindex]="tabIndex()"
  [href]="href()"
  [target]="target()"
>
  <ng-content></ng-content>
  @if (pending) {
    <div class="pending-layer">
      <ion-spinner name="crescent" class="h-4 w-4" color="dark"></ion-spinner>
    </div>
  }
</ion-button>
