import { Injectable } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import {
  CreateHotToastRef as NgNeatCreateHotToastRef,
  HotToastService,
  ObservableMessages,
  ToastOptions,
  ToastType,
} from '@ngxpert/hot-toast';
import { Observable, Subject } from 'rxjs';
import { ToastAction, ToastClose, ToastComponent, ToastData, ToastMessage } from './toast.component';
import { DEFAULT_HOT_TOAST_STYLE } from './toast.model';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
export { CreateHotToastRef, HotToastRef, ToastOptions, ToastType } from '@ngxpert/hot-toast';

export interface ToastRef<Action extends ToastAction = ToastAction>
  extends Omit<NgNeatCreateHotToastRef<ToastData<Action>>, 'afterClosed'> {
  afterClosed: Observable<ToastClose>;
}

export type Options<Action extends ToastAction> = ToastOptions<Omit<ToastData<Action>, 'message'>>;

@Injectable({ providedIn: 'root' })
export class ToastService {
  constructor(private readonly hotToast: HotToastService) {}

  error<Action extends ToastAction>(message: ToastMessage, options?: Options<Action>) {
    return this.showToast<Action>('error', message, {
      duration: 5000,
      autoClose: true,
      ...options,
    });
  }

  warning<Action extends ToastAction>(message: ToastMessage, options?: Options<Action>) {
    return this.showToast<Action>('warning', message, options);
  }

  success<Action extends ToastAction>(message: ToastMessage, options?: Options<Action>) {
    return this.showToast<Action>('success', message, options);
  }

  info<Action extends ToastAction>(message: ToastMessage, options?: Options<Action>) {
    return this.showToast<Action>('info', message, options);
  }

  loading<Action extends ToastAction>(message: ToastMessage, options?: Options<Action>) {
    return this.showToast<Action>('loading', message, options);
  }

  /** @deprecated */
  observe<T, Action extends ToastAction>(messages: ObservableMessages<T, Omit<ToastData<Action>, 'message'>>) {
    return this.hotToast.observe(messages);
  }

  close(id: string) {
    return this.hotToast.close(id);
  }

  showToast<Action extends ToastAction>(type: ToastType, message: ToastMessage, options?: Options<Action>) {
    const toastOptions = this.getOptions<Action>({ ...(options ?? {}), data: { ...(options?.data ?? {}), message } });
    const toastRef = this.getToastRef<Action>(type, toastOptions) as unknown as ToastRef<Action>;
    return toastRef;
  }

  private getToastRef<Action extends ToastAction>(type: ToastType, options: Options<Action>) {
    switch (type) {
      case 'success':
        return this.hotToast.success(ToastComponent, options);
      case 'error':
        return this.hotToast.error(ToastComponent, options);
      case 'warning':
        return this.hotToast.warning(ToastComponent, options);
      case 'info':
      default:
        return this.hotToast.info(ToastComponent, options);
    }
  }

  private getOptions<Action extends ToastAction>(options: Options<Action>): ToastOptions<ToastData<Action>> {
    return {
      ...options,
      style: DEFAULT_HOT_TOAST_STYLE,
      // Icon is set in the toast component
      icon: '',
      data: {
        ...(options?.data ?? {}),
        message: options.data?.['message'] as ToastMessage,
        actionClicked: new Subject<Action['id']>(),
      },
    };
  }
}
