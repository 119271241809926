<button
  [type]="type()"
  [disabled]="pending || disabled()"
  mat-flat-button
  [id]="id"
  [color]="color"
  class="whitespace-nowrap"
  [ngClass]="class"
  [class.compact-button]="compact"
  [class.micro-button]="micro"
  [class.stretched-label]="isLabelStretched"
  [class.full-width-button]="fullWidth"
  [class.border-show]="isBorderShow"
>
  @if (label) {
    {{ label }}
  } @else {
    <ng-content />
  }
  @if (pending) {
    <div class="pending-layer">
      <mat-spinner [diameter]="16" [ngClass]="color ? 'quaternary-spinner' : 'secondary-spinner'" />
    </div>
  }
</button>
